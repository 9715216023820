<template>
    <main class="attestation">
        <router-link class="attestation__link attestation__link_back link link_red"
            v-if="isReportTotalLinkVisible"
            :to="{ name: 'AttestationTotal'}"
        >
            Вернуться к общим данным аттестации
        </router-link>
        <spinner class="attestation__spinner" v-if="status === 'loading'" />
        <error-message class="attestation__error-message" v-else-if="status === 'error'">
            Не удалось загрузить информацию об аттестации.
        </error-message>
        <p class="attestation__date h2" v-if="dateOfReport">Дата отчета: {{dateOfReport | dateTextLong}}</p>
        <div class="attestation__filters" v-if="status === 'success' && isFiltersVisible">
            <fieldset class="attestation__radio-group attestation__radio-group_filters">
                <legend class="attestation__legend sr-only">Фильтр групп должностей</legend>
                <radio class="attestation__radio attestation__radio_filter"
                    v-for="filter in filters"
                    :key="filter.id"
                    :val="filter.id"
                    name="filter"
                    v-model="filterModel"
                >
                    {{filter.title}}
                </radio>
            </fieldset>
            <!-- <attestation-settings class="attestation-total__settings"  v-if="isShowRestaurantsFilter" /> -->
        </div>
        <router-view
            class="attestation__data"
            :filters="filters"
            :filterId="filterId"
            :userAttestationUnits="userAttestationUnits"
            :status="status"
            :isFiltersVisible="isFiltersVisible"
            :isReportTotalLinkVisible="isReportTotalLinkVisible"
            :departments="departments"
            :calcWorkersTotal="calcWorkersTotal"
        />
    </main>
</template>

<script>
    import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
    import parseISO from "date-fns/parseISO";
    import { dateTextLong } from "@/helpers/daterange";
    import AttestationSettings from "./attestation/common/AttestationSettings";

    export default {
        name: "Attestation",
        components: {
            AttestationSettings
        },
        data() {
            return {
                status: "loading"
            };
        },
        computed: {
            ...mapState({
                data: state => state.attestation.data,
                filterId: state => state.attestation.filterId,
                reportRestaurants: state => state.report.restaurants,
                isFiltersVisible: state => state.attestation.isFiltersVisible,
                isReportTotalLinkVisible: state => state.ui.isReportTotalLinkVisible,
                isShowRestaurantsFilter: state => state.ui.isShowRestaurantsFilter
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
                "reportKey",
                "userAttestationDepartments"
            ]),
            filters() {
                return [
                    { id: "all", title: "Все" },
                    { id: "administrator", title: "Управляющий персонал" },
                    { id: "ordinary", title: "Линейный персонал" }
                ];
            },
            filterModel: {
                get() {
                    return this.filterId;
                },
                set(filterId) {
                    this.setAttestationFilter(filterId);
                }
            },
            userAttestationUnits() {
                return {
                    ids: [
                        ...this.userRestaurants?.ids,
                        ...this.userAttestationDepartments?.ids
                    ],
                    byId: {
                        ...this.userRestaurants?.byId,
                        ...this.userAttestationDepartments?.byId
                    }
                };
            },
            departments() {
                if (this.data?.restaurants) {
                    return Object.values(this.data?.restaurants);
                }
            },
            dateOfReport() {
                if (this.data?.date_of_report) {
                    return parseISO(this.data?.date_of_report);
                }
            }
        },
        methods: {
            ...mapMutations([
                "setAttestationFilter"
            ]),
            ...mapActions([
                "requestAttestation"
            ]),
            showReport() {
                this.status = "success";
            },
            loadAttestation() {
                this.status = "loading";
                this.requestAttestation().then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            },
            calcWorkersTotal(workers) {
                let workers_count = workers.length;
                let attestatedWorkers = workers.filter(worker => worker.status);
                let validated_percent = workers_count !== 0 ? attestatedWorkers.length / workers_count : 0;
                let average_validation = attestatedWorkers.length !== 0
                    ? (attestatedWorkers.reduce((total, { percentage })=> total + percentage, 0) / attestatedWorkers.length)
                    : 0;

                return {
                    workers_count,
                    validated_percent,
                    average_validation
                };
            }
        },
        filters: {
            dateTextLong
        },
        created() {
            if (this.departments) {
                this.showReport();
            } else {
                this.loadAttestation();
            }
        }
    }
</script>

<style lang="scss">
    .attestation__link {
        &_back {
            display: inline-block;
            margin-bottom: 10px;

            @include desktop {
                margin-bottom: 15px;
            }
        }
    }
    .attestation__date {
        margin-bottom: 15px;
    }
    .attestation__radio-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        @include desktop {
            flex-direction: row;
            margin-bottom: 15px;
        }
    }
    .attestation__radio {
        & + & {
            margin-top: 5px;

            @include desktop {
                margin: 0 0 0 20px;
            }
        }
    }
</style>
